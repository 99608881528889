@font-face {
  font-family: 'c64Mono';
  src: url('../public/fonts/C64_Pro_Mono-STYLE.woff2') format('woff');
}
@font-face {
  font-family: 'c64';
  src: url('../public/fonts/C64_Pro-STYLE.woff2') format('woff');
}
@font-face {
  font-family: 'Berkeley';
  src: url('../public/fonts/BerkeleyMono-Regular.woff2') format('woff2');
}

@font-face {
  font-family: 'AuthenticSans';
  src: url('../public/fonts/AUTHENTICSans-150.woff') format('woff');
}

@font-face {
  font-family: 'BasierRegular';
  src: url('../public/fonts/basiercircle-regular-webfont.woff') format('woff');
}

@font-face {
  font-family: 'FetteFraktur';
  src: url('../public/fonts/FetteFrakturLTStd.woff2') format('woff2');
}

@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Dancing+Script:wght@700&family=Kelly+Slab&family=Staatliches&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
html,
body {
  height: 100%;
  background-color: black;
  font-family: 'BasierRegular';
  user-select: none;
  overscroll-behavior: none;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes zoom-in-zoom-out {
  0% {
    transform: scale(0.85, 0.85);
  }
  100% {
    transform: scale(1, 1);
  }
}

a:link {
  color: 'green';
  text-decoration: none;
}

::-webkit-scrollbar {
  display: none;
}
